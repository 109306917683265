import React, { useState, useEffect } from "react";
import Layout from "./layout/Layout";
import "./shared/Fileupload/Analysis.scss";
import { useTranslation } from "react-i18next";
import { AnalysisService } from "../../src/utils/services/analysis.service";
import { ApiService } from "../../src/utils/services/api";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Auth from "@aws-amplify/auth";
import Swal from "sweetalert2";
import { useStore } from "../../src/Store";
import RoleType from "../utils/constants/RoleType";
import { FaRedo } from "react-icons/fa";
import { SurveyService } from "../utils/services/survey.service";
import Loader from "../containers/Loader";
import { ClientService } from "../utils/services/client.service";
import {
  Grid,
} from "@material-ui/core";

const surveyService = new SurveyService();
const FileSaver = require("file-saver");
const initialValues = {
  admin_comments: "",
};
const savedValues = {
  admin_comments: "",
};

const apiService = new ApiService();
const analysisService = new AnalysisService();
const fileTypesforCsv = [".csv"];
const fileTypesforJpg = [".png", ".jpeg"];


export default function SurveyResults({ title }) {
  const workDesignSurveyId = "9fa8164a-c76d-446f-bfa9-8bae211aaa1f"
  const lstShortSurvey = [
    "2eeff6e1-f528-4a39-ae66-17f30bac35aa",
    "bf43c9ca-2e2b-49c6-a537-3b730db8be92",
  ];
  const tenantShortSurveySkipDecember = [
    "50a6d4b4_4dd7_43e3_903e_247646f56ff7", //ヴィス東京
    "307897de_21c2_4254_b93a_e41d3d55c460", //ヴィス名古屋
    "1ee027e3_d100_4357_911d_135e4eff1225", //ヴィス大阪
  ];
  const [key, setKey] = useState("data-analysis");
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("");
  const { tenant } = useStore();
  const [User, setUser] = useState("");
  const [cognitoTenant, setCognitoTenant] = useState("");
  const [EmbedUrl, setEmbedUrl] = useState("");
  const location = useLocation();
  const [formValues, setFormValues] = useState(null);
  const [userType, setUserType] = React.useState("user");
  const [editAdmin, setEditAdmin] = useState(false);
  const [index, setIndex] = useState(0);
  const [surveys, setSurveys] = React.useState();
  const [notificatedAt, setNotificatedAt] = React.useState();
  const [timestamp, setTimestamp] = useState(0);

  const [surveyId, setSurveyId] = React.useState();
  const [responseRate, setresponseRate] = React.useState();
  const [userAnsweredCount, setUserAnsweredCount] = React.useState();
  const [surveyName, setSurveyName] = React.useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [adminLoader, setAdminLoader] = useState(false)
  const [plan, setPlan] = useState(null);
  const clientService = new ClientService();
  const changeLang = (val) => {
    setLang(val);
    sessionStorage.setItem("lang", val);
    i18n.changeLanguage(val);
  };

  const tokyoDateDisplay = (ts) => {
    const date = new Date(ts * 1000);
        
    const options = { 
      timeZone: 'Asia/Tokyo', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    
    const formattedDate = date.toLocaleDateString('ja-JP', options);
  
    return `${formattedDate}配信`;
  }

  const formatTimestampToJST = (timestampSeconds) => {
    const timestampUtc = new Date(timestampSeconds * 1000);
  
    const jstFormatter = new Intl.DateTimeFormat('ja-JP', {
      timeZone: 'Asia/Tokyo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    const formattedTime = jstFormatter.format(timestampUtc);
  
    return formattedTime;
  };

  const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");
  const embed_options = {
    url: "",
    container: "",
    // scrolling: "yes",
    // height: "AutoFit",
    // resizeHeightOnSizeChangedEvent: false,
    // iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    // width: "100%",
    // withIframePlaceholder: true,
    // // locale: "en-US",
    footerPaddingEnabled: true,
    // // sheetId: 'YOUR_SHEETID',  // use this option to specify initial sheet id to load for the embedded dashboard
    // sheetTabsDisabled: true, // use this option to enable or disable sheet tab controls in dashboard embedding
    // printEnabled: true, // use this option to enable or disable print option for dashboard embedding
    // undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
    // resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
    // // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
  };

  const getCompany = async () => {
    if (tenant) {
      const response_company = await clientService.getCompany(tenant);
      if (response_company) {
        setPlan(response_company.data.plan);
      }
    }
  }



  const getSurveyByUser = async () => {
    try {
      const response = await surveyService.getSurveys(title);
      if (response) {
        setSurveys(response.data);
      }      
    } catch (err) { }
  };

  const getDeliveryDateByTenant = async (tenant) => {
    try {
      const response = await surveyService.getSurveyNotification(tenant);
      if (response) {
        const lstNotifyAt = response?.data;
        if (
          lstNotifyAt &&
          tenantShortSurveySkipDecember.indexOf(tenant) !== -1
        ) {
          for (const keySurveyId in lstNotifyAt) {
            if (lstShortSurvey.indexOf(keySurveyId) === -1) {
              continue;
            }
            const lstTime = lstNotifyAt[keySurveyId];
            const lstSkipDecember = lstTime.filter((item) => {
              const notifyDate = new Date(item * 1000);
              if (
                notifyDate.getFullYear() === 2024 &&
                notifyDate.getMonth() === 11
              ) {
                return null;
              }
              return item;
            });
            lstNotifyAt[keySurveyId] = lstSkipDecember;
          }
        }
        setNotificatedAt(lstNotifyAt);
      }
    } catch (err) { }
  };

  const getVerifier = async () => {
    try {
      const response = await apiService.getToken();
      const decoded = jwt_decode(response);
      setUser(decoded["cognito:username"]);
      setCognitoTenant(decoded["cognito:groups"][0]);
    } catch (err) { }
  };

  const getQuickSightAnalysis = async () => {
    document.querySelector('#experience-container').innerHTML = ``;
    const {
      createEmbeddingContext,
    } = QuickSightEmbedding;

    const embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });

    const params = {
      user: User,
      analysis: surveyId,
      tenant: cognitoTenant,
      tenant_dashboard: tenant,
      userType: userType,
    };

    console.log("params")
    console.log(params)
    const tokyoTimeString = formatTimestampToJST(timestamp)
    console.log(tokyoTimeString)
    const res = await analysisService.getQuickSightDashboardAnalysis(params);

    const frameOptions = Object.assign({}, embed_options);
    frameOptions["url"] = res.data.EmbedUrl
    frameOptions["container"] = document.getElementById("experience-container");

    // const response = await surveyService.getRecentNotificatedAt(tenant, surveyId);

    let recentNotificatedAt = null;
    if (timestamp === 0) {
      console.log("Failed to get recentNotificatedAt")
      return
    } else {
      recentNotificatedAt = tokyoTimeString;
    }
   
    const parameters = [
      {
        Name: 'recentNotificatedAt',
        Values: [recentNotificatedAt]
      }
    ]

    const contentOptions = {
      parameters: parameters.filter((param) => {
        // Remove if all elements of the list are undefined or null
        // QuickSight returns no data if we send empty parameters
        return !param.Values.every((value) => value === undefined || value === null);
      }),
    }

    const embeddedDashboardExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
  }

  const getSurveyResponseRate = async (selSurveyId) => {
    setLoader(true);
    // 回答率と回答者数を取得
    const params = {
      selSurveyId,
      tenant,
      timestamp,
    };
    setresponseRate();
    setUserAnsweredCount();
    const response = await surveyService.getSurveyResponseRate(params);
    if (response) {
      setresponseRate(response.data.responsesRate);
      setUserAnsweredCount(response.data.surveyResponsesCount);
    }
  };

  const setTypeOfUser = async () => {
    const user = await Auth.currentUserInfo();
    setUserType(user.attributes["custom:role"]);
  };

  useEffect(() => {
    getCompany();
  }, [tenant]);

  useEffect(() => {
    if (tenant && location && location.pathname) {
      getAdminComments();
      getSurveyByUser();
      getDeliveryDateByTenant(tenant);
    }
  }, [location, tenant]);

  useEffect(() => {
    if (notificatedAt && notificatedAt[surveyId] &&  notificatedAt[surveyId].length > 0 ){
      setTimestamp(notificatedAt[surveyId][0]);
    } else {
      setTimestamp(0);
    }
  }, [surveyId, notificatedAt]);

  useEffect(() => {
    getVerifier();
    if (key === "data-analysis" && tenant && User && location && surveyId) {
      getQuickSightAnalysis();
    }
    if (i18n.language) {
      changeLang(i18n.language);
    }
    getSurveyResponseRate(surveyId);
    setTypeOfUser();
  }, [i18n.language, User, location, userType, timestamp]);

  const getAdminComments = async () => {
    const params = {
      analysis: location.pathname.split("/")[1],
    };
    const response = analysisService.getAdminComments(params, tenant);
    try {
      const res = await response;
      if (res) {
        savedValues["admin_comments"] = res.data.comments;
        if (!res.data.comments?.length) {
          setEditAdmin(true);
        } else {
          setEditAdmin(false);
        }
      }
    } catch {
      savedValues["admin_comments"] = "";
      setEditAdmin(true);
    } finally {
      setFormValues(savedValues);
    }
  };

  const saveAdminComments = async (e) => {
    setAdminLoader(true)
    const data = {
      comments: e.admin_comments,
      analysis: location.pathname.split("/")[1],
    };
    const response = await analysisService.postAdminComments(data, tenant);
    if (response) {
      setAdminLoader(false)
      setFormValues(initialValues);
      getAdminComments();
      Swal.fire({
        title: t("comments_submitted_successfuly"),
        confirmButtonColor: "#D57B85",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      })
    }
  };

  const onEdit = async (formikType) => {
    if (formikType === "comments") {
      setEditAdmin(true);
    }
  };

  const onCsvDownload = async () => {
    setLoader(true);
    const params = {
      surveyId,
      tenant,
      timestamp: timestamp.toString(),
      format: "labeled",
    };
    const response = await surveyService.onExportCsvDownload(params);
    if (response) {
      setLoader(false);
      setShow(false);
      FileSaver.saveAs(response.data, `${surveyName}_ラベルデータ_${tenant}.csv`);
    }
  };

  const onStatusCsvDownload = async () => {
    setLoader(true);
    const params = {
      surveyId,
      tenant,
      format: "labeled",
    };
    const response = await surveyService.onExportStatusCsvDownload(params);

    if (response) {
      setLoader(false);
      setShow(false);
      const date = new Date().toLocaleString('sv').replace(/\D/g, '');
      FileSaver.saveAs(response.data, `${surveyName}_回答状況_${date}.csv`);
    }
  };

  const handleSurveyResponseByTenant = (e) => {
    setSurveyId(e);
    const test = surveys.find(elm => {
      return elm.survey_id === e
    })
    setSurveyName(test.survey_title);
  };

  const handleNotificatedAt = (e) => {
    setTimestamp(e);
  };
  
  return (
    <Layout>
      <div className="col-12 px-2 child-layout">
        <Formik
          initialValues={formValues || initialValues}
          enableReinitialize={true}
          validateOnMount
        >
          {(formik) => {
            return (
              <Form className="text-purple p-1 h-100">
                <Grid container >

                  {/* タイトル */}
                  <Grid item xs={4} className="grid-padding">
                    <div className="d-flex justify-content-between" >
                      <div className="p-1 d-flex flex-column">
                        <div className="d-flex">
                          <h3 className="mb-0 text-body">
                            <b>{t("survey_results")}</b>
                          </h3>
                        </div>
                        <p className="text-body mb-0">{t("survey_results_title")}</p>
                      </div>
                    </div>
                  </Grid>
                  {/* コメント部分 */}
                  <Grid item xs={7} className="grid-padding">
                    <label
                      type="text-in"
                      className="clr-14213D pt-1"
                      htmlFor="admin_comments"
                    >
                      <h4>
                        <b>{t("admin_comments")}</b>
                      </h4>
                    </label>
                    <Field
                      as="textarea"
                      id="admin_comments"
                      name="admin_comments"
                      className="admin_comments"
                      rows={3}
                      disabled={
                        ![RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                          userType
                        ) || !editAdmin
                      }
                      placeholder={t("admin_comments")}
                    />
                    <div className="text-danger">
                      <ErrorMessage
                        name="admin_comments"
                        className="text-danger"
                      />
                    </div>
                  </Grid>

                  {/* 編集ボタン */}
                  <Grid item xs={1} className="grid-padding">
                    {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(
                      userType
                    ) && (
                        <div className=" align-self-end text-nowrap">
                          {!editAdmin ? (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => onEdit("comments")}
                            >
                              {t("edit")}
                            </button>
                          ) : adminLoader ? (
                            <Loader />
                          ) : (
                            <button
                              className="btn btn-14213D w-100"
                              onClick={() => saveAdminComments(formik.values)}
                            >
                              {t("save")}
                            </button>
                          )}
                        </div>
                      )}
                    <div className="text-danger">
                      <ErrorMessage
                        name="admin_comments"
                        className="text-danger"
                      />
                    </div>
                    <button
                      className="mt-3 mb-0 bg-white border-0"
                      onClick={(e) => {
                        getQuickSightAnalysis();
                        getAdminComments();
                      }}
                    >
                      <h4 className="mt-0 mb-0 text-dark p-0">
                        <FaRedo />
                      </h4>
                    </button>
                  </Grid>

                  {/* csvダウンロード と サーベイ選択*/}
                  <Grid item xs={12} className="grid-padding">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      {tenant && surveys && surveys.length !== 0 ? (
                        <select
                          name="survey"
                          className="select-box-long"
                          onChange={(e) => {
                            handleSurveyResponseByTenant(e.target.value);
                          }}
                        >
                          <option value="DEFAULT" selected disabled>
                            {t("select_survey")}
                          </option>

                          {surveys && surveys.map((elem, index) => {
                            // elemに対する条件を確認する条件文を追加する
                            if (plan !== "free_survey" || elem.survey_id === workDesignSurveyId) {
                              return (
                                <option key={index} value={elem.survey_id}>
                                  {elem.survey_title}
                                </option>
                              );
                            } else {
                              // elemに対する条件が満たされない場合の処理を記述する（省略可）
                              return null;
                            }
                          })}
                        </select>
                      ) : tenant ? (
                        <h4 classNa me="text-dark">{t("no_survey")}</h4>
                      ) : (
                        <></>
                      )
                      }
                    </Grid>

                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      {tenant && surveys && surveys.length !== 0 ? (
                        <select
                          name="delivery-date"
                          className="select-box-long"
                          onChange={(e) => {
                            handleNotificatedAt(e.target.value);
                          }}
                          defaultValue={notificatedAt && notificatedAt[surveyId] && notificatedAt[surveyId].length > 0 ? notificatedAt[surveyId][0] : 0}
                        >

                          {notificatedAt && notificatedAt[surveyId] &&  notificatedAt[surveyId].length > 0 ? (
                            notificatedAt[surveyId].map((elem, index) => {
                              // elemに対する条件を確認する条件文を追加する
                              if (plan !== "free_survey" || surveyId === workDesignSurveyId) {
                                return (
                                  <option key={index} value={elem}>
                                    {tokyoDateDisplay(elem)}
                                  </option>
                                );
                              } else {
                                // elemに対する条件が満たされない場合の処理を記述する（省略可）
                                return null;
                              }
                            }) 
                          ) : (
                            <option value={0} selected disabled>
                              {t("select_delivery_date")}
                            </option>
                          )}
                        </select>
                      ) : tenant ? (
                        <h4 classNa me="text-dark">{t("no_survey")}</h4>
                      ) : (
                        <></>
                      )
                      }

                      {tenant && [RoleType.ADMIN].includes(userType) ? (
                        <div>
                          <button
                            className="btn btn-primary m-3"
                            onClick={onCsvDownload}
                            disabled={!surveyId}
                          >
                            {t("result_csv_download")}
                          </button>
                          <button
                            className="btn btn-primary m-3"
                            onClick={onStatusCsvDownload}
                            disabled={!surveyId}
                          >
                            {t("status_csv_download")}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )
                      }
                    </Grid>
                  </Grid>

                  {/* 回答者数と回答率を表示 */}
                  {tenant && surveys && surveys.length !== 0 ? (
                    <Grid className="grid-padding">
                      <div className="d-flex" >
                        <div className="d-flex flex-column">
                          <h5 className="mb-2 text-body">
                            <b>{t("surveyNumberofRespondents")} : {userAnsweredCount} {t("person")}</b>
                          </h5>
                          <div className="flex-column">
                            <h5 className="mb-2 text-body">
                              <b>{t("surveyResponseRate")} &nbsp;&nbsp;&nbsp;&nbsp; : {responseRate} {t("percent")}</b>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ) : tenant ? (
                    <h4 classNa me="text-dark">{t("no_survey")}</h4>
                  ) : (
                    <></>
                  )
                  }

                  {/* DASHBOARD EMBEDDING */}
                  <Grid item xs={12} style={{ height: "100vh" }} className="grid-padding" >
                    <div id="iframeBlock">
                      <div className="iframe-withoutTab">
                        <div className="iframeBody">
                          <div id="experience-container" className="container-dashboard-embedding" style={{ height: 'calc(100vh - 16px)' }}></div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
{/* 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {t("survey_response_list_id")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {surveys && surveys.length !== 0 ? (
            <select
              name="responseId"
              onChange={(e) => {
                handleSurveyResponseByTenant(e.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {t("select")}
              </option>
              {surveys &&
                surveys.map((elem, index) => (
                  <option key={index} value={elem.survey_id}>
                    {elem.survey_title}
                  </option>
                ))}
            </select>
          ) : (
            <h4 className="text-dark">{t("no_survyes")}</h4>
          )}
        </Modal.Body>

        <Modal.Footer>
          {loader ? (
            <Loader />
          ) : (
            <Button
              variant="btn btn-14213D "
              disabled={!surveyId}
              onClick={onCsvDownload}
            >
              {t("submit")}
            </Button>
          )}
        </Modal.Footer>
      </Modal> */}
    </Layout >
  );
}
